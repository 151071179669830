@import '../styles/colors';

.wrapper {
  padding: 12px 15px;
  min-width: 250px;

  .content {
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    color: #4E575C;

    strong {
      font-weight: bold;
    }
  }
}

.trigger {
  cursor: pointer;
}
