/* primary palette */
$color-white: #FFF;
$color-black: #4C575D;

$color-gray: #FBFBFB;
$color-disabled-gray: #CCCED0;
$color-dark-gray: #F3F3F3;
$color-darker-gray: #B1B1B1;
$color-darkest-gray: #848A8D;

$color-blue: #49A6FF;
$color-lightest-blue: #EAF4FF;
$color-dark-blue: #056fb5;
$color-darkest-blue: #265C7F;
$color-dark-blue-black: #003558;

/* accents */
$color-accent-black: #000;
$color-accent-red: #D16764;
$color-accent-bright-red: #ff0000;
$color-accent-orange: #EE8D41;
$color-accent-dark-yellow: #C3B750;
$color-accent-light-yellow: #FEFFC3;
$color-accent-green: #1EBFA2;
$color-accent-turquoise: #09CCE2;
$color-accent-blue: #C7F5E6;

$color-online: #4DEB98;
$color-offline: #EB4F4F;
$color-idle: $color-disabled-gray;

$color-button-disabled: #BBBABA;
$color-button-hover: #50C9FC;
