@import '../styles/colors';

.wrapper {
  padding: 12px 15px;
  min-width: 250px;

  .content {
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    margin-bottom: 27px;
    color: #4E575C;

    strong {
      font-weight: bold;
    }
  }

  .learnMore {
    display: inline-block;
    border: 1px solid $color-accent-turquoise;
    border-radius: 3px;
    color: $color-accent-turquoise;
    font-size: 12px;
    padding: 2px 8px;
    text-align: center;

    &:hover {
      text-decoration: none;
    }
  }
}

.trigger {
  cursor: pointer;

  * {
    pointer-events: none;
    color: $color-accent-turquoise;
  }

  > * {
    opacity: 0.6;
  }

  &:hover > * {
    opacity: 1;    
  }
}
