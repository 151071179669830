.list {
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  margin: 0 -16.5px 0 -7.5px;
}

.listGrid {
  margin: 0 !important;
  padding: 0 0 70px 0 !important;
}

.listGridItem {
  width: 215px !important;
  height: 195px !important;
  padding: 0 !important;
  margin: 0 7.5px 20px;
}

.listGridItem .listGridMediaWrapper {
  width: 100%;
  min-height: 162px;
  height: 162px;
  margin-bottom: 10px;
  background: #ccced0;
}

.listGridItem .listGridImage {
  width: 100%;
  height: 100% !important;
  background-image: url("../../../../assets/loading.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
}

.listGridItem .listGridText {
  font-size: 16px;
  letter-spacing: 0;
  color: #4c575d;
  opacity: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  cursor: pointer;
  /* autoprefixer: ignore next */
  display: -webkit-box;
  /* autoprefixer: ignore next */
  -webkit-line-clamp: 1;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}

.listGridItem .listGridTitleBar {
  background: transparent;
  color: #ffffff;
}

.listGridItem .listGridCheckbox {
  color: #ffffff;
}

.icon {
  color: white !important;
}
