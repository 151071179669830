@import "../../../../components/shared/styles/colors";

.container {
  width: 100%;
  max-width: 318px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  right: -20px;
  overflow: hidden;
  background-color: #ffffff;
  transition: .3s;
  box-shadow: -4px 0px 12px rgba(0, 0, 0, 0.25);
}

.hide {
  max-width: 0;
}

.title {
  color: #4e575c;
  font-size: 1.4rem !important;
  font-weight: 500 !important;
  padding: 8px 0;
}

.listGrid {
  margin: 0 !important;
  padding: 8px 12px 0 24px !important;
  overflow: auto;
}

.listGridItem {
  width: 100%;
  height: 100%;
  padding: 0 !important;
  margin: 0 0 20px;
}

.listGridItem .listGridMediaWrapper {
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  background: #ccced0;
}

.listGridItem .listGridImage {
  width: 100%;
  height: 100% !important;
  background-image: url("../../../../assets/loading.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
}

.listGridItem .listGridText {
  font-size: 16px;
  letter-spacing: 0;
  color: #4c575d;
  opacity: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  cursor: pointer;
  /* autoprefixer: ignore next */
  display: -webkit-box;
  /* autoprefixer: ignore next */
  -webkit-line-clamp: 1;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}

.listGridItem .listGridTitleBar {
  background: transparent;
  color: #ffffff;
}

.listGridItem .listGridCheckbox {
  color: #ffffff;
}

.icon {
  color: white !important;
}

.toggle {
  top: 50%;
  right: calc(100% - 25px);
  width: 25px;
  height: 25px;
  padding: 3px;
  outline: none;
  font-size: 1.8em;
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-blue !important;
  border-radius: 50%;
  text-align: center;
  transition: left 0.25s;
  background: $color-white;
}

.toggleOnly {
  position: fixed;
  right: 8px;
}
