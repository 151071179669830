@import './colors';
@import './breakpoints';

$header-default-height: 40px;

$global-header-height: 85px;
$expandable-pane-height: 100vh;

$message-box-height-md: 330px;
$message-box-collapsed-md: calc(#{$header-default-height} - #{$message-box-height-md});

$message-box-height-lg: 465px;

$lg-collapsed-top: calc(#{$message-box-height-lg} - #{$header-default-height});
$md-collapsed-top: calc(#{$message-box-height-md} - #{$header-default-height});

$message-box-width: 255px;


@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: .9;
  }
  50% {
    transform: scale(.5);
    opacity: .5;
  }
  100% {
    transform: scale(1);
    opacity: .9;
  }
}


@keyframes pulseSmall {
  0% {
    transform: scale(.9);
  }
  50% {
    transform: scale(1.1);

  }
  100% {
    transform: scale(.9);

  }
}

$button-font-size: 16px;
$button-border-radius: 4px;

$box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.05);
$image-box-shadow: 0px 4px 16px 2px rgba(0, 0, 0, 0.25);
$preview-box-shadow: 0px 3px 6px #00000029;
$nav-box-shadow: 3px 0px 6px #00000029;

@mixin fullWidthNavBarFix () {
    width: calc(100% - 60px);

    @media (max-width: $breakpoint-bs-sm) {
      width: 100%;
    }
}

@mixin button () {
  border-radius: $button-border-radius;
  font-size: $button-font-size;
  min-width: 150px;
  padding: 14px 20px;
  text-align: center;
  width: 100%;
}

@mixin primaryButton ($color) {
  @include button();
  background-color: $color;
  border: 1px solid $color;
  color: $color-white;
}

@mixin secondaryButton ($color) {
  @include button();
  background-color: $color-white;
  border: 1px solid $color;
  color: $color;
}

@mixin noOutline ($color) {
  @include button();
  background-color: transparent !important;
  border: none !important;
  color: $color;
  text-transform: uppercase;
  font-size: 0.95em;
}

@mixin forToolbar ($color) {
  background-color: transparent !important;
  border: none !important;
  color: $color;
  text-transform: uppercase;
  font-size: 0.95em;
  min-width: 0;
  width: auto;
  text-transform: none;
  padding: 2px 10px;
  margin-right: 5px;
}

@mixin form-labels () {
  font-size: 0.75em;
  margin-bottom: .1rem;
  color: $color-black;
}

@mixin format-status-priority ($width) {
  width: $width;
  text-transform: uppercase;
  font-size: 0.75em;
  letter-spacing: 1px;
  font-weight: 600;
  text-align: center;
  border-radius: 25px;
  padding-top: 6px;
  padding-bottom: 6px;

  @media (max-width: $breakpoint-medium) {
    width: 45px;
    font-size: 0.5em;
  }
}

@mixin box-shadow ($shadow) {
  box-shadow: $shadow;
}

@mixin list-hover-styles ($shadow, $border) {
  box-shadow: $shadow;
  border: 1px solid $border;
  transition: all 0.3s;
}

@mixin input-styles () {
  padding: 6px 14px;
  border-radius: 5px;
  border: 1px solid $color-dark-gray;
}

@mixin popper-list-styles {
  ul {
    margin: -10px;

    li {
      display: block;
      margin: 0;

      button {
        background: none;
        border: none;
        color: $color-black;
        outline: none;
        padding: 10px 30px 10px 15px;
        text-align: left;
        width: 100%;

        &:hover {
          background-color: $color-gray;
        }

        > a {
          color: $color-black;
          text-decoration: none;
        }
      }
    }
  }
}

@mixin truncated-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin -webkit-clamp-lines($line-clamp: 3) {
  display: -webkit-box;
  -webkit-line-clamp: $line-clamp;
  -webkit-box-orient: vertical;
}

@mixin search-bar-input-styles () {
  input {
    background-color: $color-white;
    border: 1px solid $color-dark-gray;
    border-right: none;
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 10px 15px;
    transition: all;
    transition-duration: 150ms;
    transition-timing-function: ease-out;
    width: 100%;

    &:focus {
      outline: 0;
    }
  }
}

@mixin search-bar-button-styles () {
  button {
    background-color: transparent;
    border: 1px solid $color-dark-gray;
    border-left: none;
    border-radius: 3px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    color: $color-blue;
    margin-left: -1px;
    padding-left: 12px;
    padding-right: 12px;

    &:focus {
      outline: 0;
    }
  }
}

@mixin date-picker () {
  :global(.react-datepicker-wrapper), :global(.react-datepicker__input-container), :global(.react-datepicker__input-container input) {
    width: 100%;
  }

  :global(.react-datepicker__input-container input) {
    background: url('/static/assets/icon_small_calendar.png') no-repeat 96% center $color-white;
    @include input-styles;
  }
}

@mixin procedure-list-flex-styles () {
  :global(.date) {
    flex: 1 10%;
    min-width: 75px;
    margin-right: 15px;
    @media (max-width: $breakpoint-bs-md) {
      flex-basis: auto;
      flex-grow: 1;
    }
  }
  :global(.code) {
    flex: 1 35%;
  }
  :global(.provider) {
    flex: 1 30%;
  }
  :global(.fee) {
    flex: 1 15%;
    @media (max-width: $breakpoint-bs-md) {
      flex: 1 auto;
    }
  }
  :global(.status) {
    flex: 1 5%;
  }
}

$dashboard-widget-padding: 12px 24px;
$tab-pane-padding: 10px;
$thumbnail-small-height: 30px;
$thumbnail-small-width: 30px;
$thumbnail-large-height: 140px;
$thumbnail-large-width: 140px;

$font-size-slightly-large: 1.125em;
$font-size-large: 1.25em;
$font-size-slightly-small: 0.75em;

$toolbar-height: 36px;

$messages-toolbar-height: 80px;
$messages-chatfield-height: 110px;
$messages-chatbox-height: calc(100% - #{$messages-toolbar-height});
$messages-chatbox-height-sm: calc(100% - 150px);
$messages-chatbox-attachments-height: 100px;
$messages-chatbox-height-with-attachments: calc(100% - #{$messages-toolbar-height} - #{$messages-chatbox-attachments-height});
