.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    max-height: 100%;
}
.text{
    color: rgb(255, 255, 255);
    margin: 10px 0 0 10px
}
