.container {
    display: flex;
    align-items: flex-end;
    margin: 20px 0 30px;
}

.containerForm {
    width: 100%;
    max-width: 312px;
    margin-right: 20px;
}

.formControl {
    width: 100%;
}

.formControl .formLabel {
    position: static;
    transform: none;
    font-size: 12px;
    letter-spacing: 0;
    color: #4E575C;
    margin-bottom: 5px;
}

.formControl .select__control {
    border: 1px solid #E4E4E4;
}