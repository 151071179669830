.activeFeed {
    height: 100%;
}

.video {
    max-width: 100%;
    display: flex;
    width: 100% !important;
    background: black !important;
}

.videoFullScreen {
    height: calc(100vh - 70px) !important;
}

@media (max-device-width: 992px) and (orientation: landscape) {
    .videoFullScreen {
        height: calc(100vh - 85px) !important;
    }
}

.video video {
    width: 100% !important;
    height: auto !important;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.card {
    position: relative;
    height: 100%;
}

.cardActions {
    padding: 0 0 20px 30px;
    bottom: 0;
    display: block;
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    border-radius: 5px;
}

.button {
    color: #fff !important;
}

.accountAvatar {
    position: absolute;
    max-width: 250px;
    max-height: 250px;
    min-width: 50px;
    min-height: 50px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}