.container {
  font-family: Source Sans Pro, sans-serif;
  margin-top: 5%;
  color: #4c575d;
}

.container form {
    width: 100%;
}

.inputContainer {
    margin-bottom: 20px;
}

.errorLabel {
  word-wrap: break-word;
  color: #d16764;
  display: inline-block;
  font-size: 12px;
  white-space: pre-wrap;
  margin-bottom: 0;
}

.inputLabel {
  font-size: 12px;
  padding: 0 0 0 2px;
  text-transform: capitalize;
  margin-bottom: 0.1rem;
}

.logo {
  margin-bottom: 36px;
}

.logo > img {
  max-width: 100%;
}

.forgotAction {
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0.25rem;
}

.forgotAction > a {
  color: #49a6ff;
  font-weight: bolder;
  cursor: pointer;
  text-decoration: none;
}

.forgotAction > a:hover {
  color: #49a6ff;
  text-decoration: none;
}

.passwordContainer {
  position: relative;
}

.showHide {
  color: #49a6ff;
  font-weight: bolder;
  position: absolute;
  right: 5px;
  top: 0px;
  font-size: 14px;
}
