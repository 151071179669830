.groupLogo {
  background: transparent center center / 100% 100% no-repeat;
  height: 100%;
  width: 100%;

  &.forSideBar {
    left: 2;
    position: relative;
  }
}
