@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap');

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
    height: 100%;
    width: 100%;
    position: fixed;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.app {
    display: flex;
    height: 100%;
    flex-grow: 1;
    flex-direction: column;
}

.streamWrapper video::-webkit-media-controls-current-time-display,
.streamWrapper video::-webkit-media-controls-timeline,
.streamWrapper video::-webkit-media-controls-play-button,
.streamWrapper video::internal-media-controls-overflow-button {
    display: none;
}

.Blink {
    animation: blinker 1.5s cubic-bezier(.5, 0, 1, 1) infinite alternate;
}

@keyframes blinker {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.react-grid-item:hover {
    z-index: 10;
}


.user-card .MuiCardActions-root {
    background: transparent;
}

.MuiBox-root.conference-footer {
    padding-bottom: 30px;
    padding-top: 30px;
    box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.25);
}

.app-logo {
    position: absolute;
    left: calc(100% - 186px);
    z-index: 999;
    top: calc(100% - 73px);
    width: 156px;
    height: 31px;
}

/* START: RESPONSIVENESS FOR CONFERENCE VIEW */
/* Landscape */
@media (max-device-width: 992px) and (orientation: landscape) {
    .app-logo {
        top: calc(100% - 55px);
    }

    .MuiBox-root.conference-footer {
        height: 80px;
        padding-top: 8px;
    }
}

@media (max-width: 768px) {
    .conference-layout {
        background: black !important;
    }

    .conference-layout .react-resizable-handle {
        display: none;
    }

    .conference-layout .react-grid-item.user-card.user-card_local .MuiCardHeader-root {
        display: none;
    }

    .conference-layout .react-grid-item.user-card:not(.user-card_local) .MuiPaper-root {
        border-radius: 0 !important;
    }

    .MuiBox-root.conference-footer {
        padding: 0;
    }

    .MuiBox-root.conference-footer header {
        background: #fff !important;
        box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16) !important;
        height: 70px;
        justify-content: center;
        position: fixed;
        top: calc(100% - 70px);
        width: 100%;
        -webkit-box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16) !important;
        -moz-box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16) !important;
    }
}

/* END: RESPONSIVENESS FOR CONFERENCE VIEW */


/* BEGIN HELPER CLASSES FOR ELEMENT RESPONSIVENESS */
@media (min-width: 768px) {
    .mobile-only {
        display: none !important;
    }

    .desktop-only {
        display: block !important;
    }
}

@media (max-width: 768px) {
    .mobile-only {
        display: block !important;
    }

    .desktop-only {
        display: none !important;
    }
}

/* END HELPER CLASSES FOR ELEMENT RESPONSIVENESS */
