.container {
    width: 100%;
    max-width: 318px;
    height: 100%;
    display: flex;
    align-items: flex-end;
    position: absolute;
    top: 0;
    z-index: 1001;
    border-right: 1px solid #CCCED0;
    background-color: #FFFFFF;
}

.textComposer {
    position: fixed;
    width: 100%;
    max-width: 318px;
    top: calc(100% - 67px);
    transition: .3s;
    display: flex;
    align-items: center;
    padding: 0 10px 0 10px;
    border-radius: 5px;
    box-sizing: border-box;
    height: 67px;
    border-top: 1px solid #F3F3F3;
    background-color: #FFFFFF;
}

.sendButton {
    position: absolute !important;
    right: 10px;
    font-size: 1rem !important;
}

.textComposer div {
    background-color: var(--white) !important;
}

.textComposer .MuiFilledInput-input {
    padding: 14px 32px 14px 0;
}

.textComposer {
    transition: .3s;
}

.textField {
    margin: 0 !important;
}

.textField input {
    height: 45.5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 32px 0 16px;
    border: none;
}

.header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: none;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    height: 39px;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.18);
    color: #4C575D;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
}

.header .icon {
    color: #848A8D;
}

.headerTitle {
    display: flex;
    align-items: center;
}

.headerTitle .icon {
    margin-right: 8px;
}

@media (max-width: 768px) {
    .container {
        max-width: 100%;
        padding-bottom: 70px;
    }

    .textComposer {
        max-width: 100%;
        top: calc(100% - 137px);
    }

    .header {
        display: flex;
    }
}
