:root {
    /* primary palette */
    --white: #FFF;
    --black: #4C575D;

   --gray: #FBFBFB;
   --disabled-gray: #CCCED0;
   --dark-gray: #F3F3F3;
   --darker-gray: #B1B1B1;
   --darkest-gray: #848A8D;

   --blue: #49A6FF;
   --lightest-blue: #EAF4FF;
   --dark-blue: #056fb5;
   --darkest-blue: #265C7F;
   --dark-blue-black: #003558;

    /* accents */
   --accent-black: #000;
   --accent-red: #D16764;
   --accent-bright-red: #ff0000;
   --accent-orange: #EE8D41;
   --accent-dark-yellow: #C3B750;
   --accent-light-yellow: #FEFFC3;
   --accent-green: #1EBFA2;
   --accent-turqoise: #09CCE2;
   --accent-blue: #C7F5E6;

   --online: #4DEB98;
   --offline: #EB4F4F;
   --idle: var(--disabled-gray);
}
