.container {
    position: relative;
    overflow: hidden;
}

.headerWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.containerWrapper {
    width: 100%;
    height: 100vh;
    max-width: 681px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 70px;
}

.media {}

.title {
    color: #4E575C;
    font-size: 2rem !important;
    font-weight: 300 !important;
}



.skipButton {
    background-color: #FF0000;
    color: #FFFFFF;
    cursor: pointer;
    font-size: 16px;
    height: 40px;
    padding: 10px 24px;
    border-radius: 4px;
}

.skipButton:hover span {
    margin-left: 7px;
    transition: .3s;
}

.skipButton span {
    margin-left: 3px;
    transition: .3s;
}