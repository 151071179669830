.body {
    padding: 0 20px 67px 20px;
    background-color: var(--white);
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    overflow-y: auto;
}

.bodyWrapper {
    width: 100%;
    padding-top: 20px;
}

.message {
    width: 100%;
    max-width: 90%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    float: right;
    margin-bottom: 20px;
    border: 1px solid #60A4FE;
    border-radius: 5px;
    padding: 14px 10px 12px 17px;
    overflow-x: hidden;
}

.messageRemote {
    width: 100%;
    max-width: 85%;
    display: flex;
    flex-direction: column;
    float: left;
    margin-bottom: 20px;
    border: 1px solid #60A4FE;
    border-radius: 5px;
    padding: 14px 10px 12px 17px;
    background: #F5F5F5;
    overflow-x: hidden;
}

.messageBody {
    color: #4E575C;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    margin: 0;
    word-break: break-all;
}

.messageHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--darkest-gray);
    margin-bottom: 9px;
}

.author {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: #4E575C;
    margin: 0 0 6px 0;
}

.date {
    font-size: 12px;
    margin: 0;
}

@media (max-width: 768px) {
    .body {
        height: 100%;
    }

    .bodyWrapper {
        padding-top: 59px;
    }
}
